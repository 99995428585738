<template>
  <b-row>
    <b-col md="4" sm="6" class="mb-4 align-self-center" v-for="(m, index) in myMedia" :key="m.id">
      <router-link :to="myLinks[index]">
          <b-card no-body class="m" :class="'mediaStatus-' + m.status + ' pending-' + m.pending + ' published-' + m.published">
              <div class="draftButton" v-if="!m.published || m.status < 4 || m.pending">
                <div v-if="!m.published" class="d-inline-block mx-1" :id="'pub'+m.id">
                  <BIconEyeSlashFill variant="primary" />
                  <BTooltip triggers="hover" :target="'pub'+m.id">Unpublished</BTooltip>
                </div>
                <div v-if="m.status < 4"  class="d-inline-block mx-1" :id="'status'+m.id">
                  <BIconChevronBarContract variant="primary" />
                  <BTooltip triggers="hover" :target="'status'+ m.id"> {{ $t('videos.processing') }}</BTooltip>
                </div>
                <div v-if="m.pending"  class="d-inline-block mx-1" :id="'pend'+m.id">
                  <BIconClockFill variant="primary" />
                  <BTooltip triggers="hover" :target="'pend'+m.id">Pending</BTooltip>
                </div>
              </div>
              <div v-if="m.mimeType.indexOf('image') >= 0 || m.mimeType.indexOf('video') >= 0">
                <div @mouseover="playGif" @mouseleave="stopGif" class="posterPlaceHolder" :style="'background-image: url(\''+ m.posterUrl +'\')'" 
                  :rel="index" :data-over="m.gifUrl ? m.gifUrl : m.posterUrl" :data-out="m.posterUrl">
                  <img class="img-fluid" :src="m.posterUrl" style="display:none;">
                  <b-img v-if="m.mimeType.indexOf('video') >= 0" class="mediaGridPlayButton" :src="require('@/assets/img/media-grid-play-icon.svg')" />
                </div>
              </div>
              <div v-else>
                <div class="posterPlaceHolder" style="padding: 3rem 0;">
                    <div class="text-center">
                      <svg width="54" height="72" viewBox="0 0 54 72" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M46.6105 34.1055C46.6105 45.4739 36.9853 53.4318 26.5263 53.4318C16.0674 53.4318 6.4421 45.4739 6.4421 34.1055H0C0 47.0276 10.3074 57.7139 22.7368 59.5707V72.0002H30.3158V59.5707C42.7453 57.7518 53.0526 47.0655 53.0526 34.1055H46.6105Z" fill="#9ADCDA"></path><rect x="15" width="23" height="45" rx="11.5" fill="#005B70"></rect></svg>
                    </div>
                </div>
              </div>
              <b-card-text class="m" text-tag="div">
                <div v-if="_lang.shortName === 'en'">
                  <h2 class="mediaTitle" :title="m.titleEn">{{ m.titleEn | removeLat }}</h2>
                </div>
                <div v-else>
                  <h2 class="mediaTitle" :title="m.title">{{m.title | removeLat}}</h2>
                </div>
              </b-card-text>
              <b-card-footer class="m">{{ $t('videos.author') }}: {{ m.author }}</b-card-footer>
          </b-card>
      </router-link>
    </b-col>
  </b-row>
</template>

<script>
import { BCard, BCardText, BImg, BIconClockFill, BCardFooter, BTooltip, BIconChevronBarContract, BIconEyeSlashFill} from 'bootstrap-vue'

export default {
  name : "MediaGridCMS",
  components : { BCard, BCardText, BCardFooter, BImg, BIconClockFill, BIconChevronBarContract, BIconEyeSlashFill, BTooltip },
  data : function() {
    return {
      posters: [],
      gifs: [],
      links: []
    }
  },

  props : {
    media : Array,
    type: String
  },

  created: function() {
      var gifz = [];
      for (var i = 0; i < this.myMedia.length; i++) {
        const m = this.myMedia[i];
        if (m.gifUrl) {
          var myImage2 = new Image();
          myImage2.src = m.gifUrl;
          gifz.push(myImage2);
        }
      }
      // console.log(gifz)
      this.gifs = gifz;
  },

  computed: {
    myType : function() {
      return this.type;
    },
    myMedia : function() {
      return this.media;
    },
    myLinks : function() {
      var linkz = []
      for (var i = 0; i < this.myMedia.length; i++) {
        const m = this.myMedia[i];
        var link = "";
        if (m.mimeType.indexOf("image") >= 0) {
          link = {name : 'EditImage', params: { imageId: m.id } };
        } else if (m.mimeType.indexOf("audio") >= 0) {
          link = {name : 'EditAudio', params: { audioId: m.id } };
        } else {
          link = {name : 'EditVideo', params: { videoId: m.id } };
        }
        linkz.push(link);
      }
      return linkz;
    },
    _lang: function() {
      return this.$root.lang
    }
  },

  methods : {
    playGif: function(e) {
      // const index = e.target.getAttribute("rel");
      // console.log(e.target.getAttribute('data-over'));
      if (typeof e.target.getAttribute('data-over') !== 'undefined') {
        e.target.style.backgroundImage = "url('" + e.target.getAttribute('data-over') + "')";
      }
    },

    stopGif: function(e) {
      // console.log(e.target)
      // const index = e.target.getAttribute("rel");
      // console.log(typeof e.target);
      if (typeof e.target.getAttribute('data-out') !== 'undefined') {
        e.target.style.backgroundImage = "url('" + e.target.getAttribute('data-out') + "')";
      }
    }
  }
}
</script>

<style></style>
