<template>
    <div class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col cols="12">
          <h1 class="text-center">{{ $t('audio.audio') }}</h1>
        </b-col>
        <b-col cols="12">
          <NavBar></NavBar>
        </b-col>
        <b-col cols="12" class="px-4">
          <b-overlay :show="showOverlay">
            <MediaAudioGridCMS :media="audio" />
          </b-overlay>
          <b-row v-if="loadMore">
            <b-col>
              <p class="text-center">
                <b-button variant="primary" @click="loadMoreData" class="my-4" size="lg">
                {{ $t('general.load-more') }} ({{ (page + 1) + "/" + totalPages }})
                </b-button>
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import NavBar from "@/components/common/NavBar.vue"
import MediaAudioGridCMS from '@/components/admin/_utils_/MediaAudioGridCMS.vue'

export default {
    name: "AllAudio",
    components: { NavBar, MediaAudioGridCMS },
    data() {
      return {
        showOverlay: true,
        audio: [],
        loadMore: false,
        page: 0,
        totalPages: 0,
        user : this.$root.user
      }
    },
    computed : {
      _lang: function() {
        return this.$root.lang;
      }
    },
    created() {
      this.fetchData();
    },
    methods: {
      async loadMoreData() {
        this.showOverlay = true;
        this.page++;
        await this.$http.get("/cms/api/media/all/audio/" + this.$root.lang.IANA + "?page=" + this.page, { headers: {Authorization: this.getAuthData().accessToken }}).then(r => {
          this.audio = this.audio.concat(r.data.content);
          this.loadMore = r.data.hasNext;
        }).catch(e => {
          this.handleError(e);
        })
        this.showOverlay = false;
      },
      async fetchData() {
        this.showOverlay = true;
        this.$http.get("/cms/api/media/all/audio/" + this.$root.lang.IANA + "?page=" + this.page, { headers: {Authorization: this.getAuthData().accessToken }}).then(r => {
          this.audio = r.data.content;
          this.loadMore = r.data.hasNext;
          this.totalPages = r.data.totalPages;
        }).catch(e => {
          this.handleError(e);
        });

        if (this.page > 0) {
          // console.log(this.page);
          const openedPages = this.page;
          this.loadMore = false;
          this.page = -1;
          this.audio = [];
          for (let i = 0; i <= openedPages; i++) {
            // console.log("Učitavam stranu: " + i);
            await this.loadMoreData();
          }
        }      
        this.showOverlay = false;
      }      
    },
    watch: {
      async _lang(newLang, oldLang) {
        this.fetchData();
      }
    }
  }
</script>

<style></style>
